import React from "react";
import crossBig from "../assets/cross-big.svg";
import { Row, Col, Button, Modal, Image } from "react-bootstrap";
import { saveAs } from "file-saver";
import info from "../assets/info.svg";
import { useState, useRef } from "react";
// import bg1 from "/editor/butterflys.png";
import SimpleCarousel from "./SimpleCarousel";
import URLImage from "./URLImage";
import { Stage, Layer } from "react-konva";

const images = [
  "/editor/bg_b.jpg",
  "/editor/bg_w.jpg",
  "/editor/hw.jpg",
  "/editor/leopard.jpg",
  "/editor/summer.jpg",
  "/editor/trash.jpg",
  "/editor/lgbt_1.jpg",
  "/editor/butterflys.jpg",
  "/editor/darkforest.jpg",
  "/editor/flowers_stars.jpg",
  "/editor/flowers.jpg",
  "/editor/galaxy.jpg",
  "/editor/galaxy2.jpg",
  "/editor/galaxy3.jpg",
  "/editor/Paint.jpg",
  "/editor/space.jpg",
  "/editor/underwater.jpg",
  "/editor/dust.jpg",
  "/editor/stars.jpg",
  "/editor/stars_again.jpg",
];

const imagesVerse = [
  "/editor/verse/basic_dark.jpeg",
  "/editor/verse/blue_fishes.jpeg",
  "/editor/verse/dark_fishes.jpeg",
  "/editor/verse/simple_pink.jpeg",
  "/editor/verse/blue_stars.jpeg",
  "/editor/verse/darkblue_fishes.jpeg",
  "/editor/verse/darkblue_stars_bubbles.jpeg",
  "/editor/verse/darkblue_stars.jpeg",
  "/editor/verse/Pink_fishes.jpeg",
  "/editor/verse/pink_stars.jpeg",
];

function PFPModal(props) {
  const [currentFrame, setFrame] = useState("");
  const [currentBg, setBg] = useState();
  const stageRef = useRef(null);

  const imagesArray = (isVerse) => {
    return isVerse ? imagesVerse : images;
  };

  const saveHDJPGFile = async (event) => {
    const name = props.selected.name.split("-")[0].replace(/\s/g, "");
    const uri = stageRef.current.toDataURL({ pixelRatio: 4 });
    saveAs(uri, `SOY_${name}.jpg`);
  };
  const saveHDPNGFile = async (event) => {
    const name = props.selected.name.split("-")[0].replace(/\s/g, "");
    const file = props.selected.image
      .replace(".jpg", ".png")
      .replace("merm-meta.soy.xyz/images", "edit.soy.xyz/verse/png")
      .replace("metadata.soy.xyz/images", "edit.soy.xyz/png/images");
    event.preventDefault();
    saveAs(file, `${name}.png`);
  };

  // React.useEffect(() => {
  //   setBg(
  //     props.selected.isVerse
  //       ? "/editor/verse/blue_fishes.jpeg"
  //       : "/editor/butterflys.jpg"
  //   );
  // }, []);

  return (
    <Modal
      {...props}
      size="lg"
      onShow={() => {
        console.log(props.selected.image);
        setFrame(
          props.selected.image
            .replace(".jpg", ".png")
            .replace("merm-meta.soy.xyz/images", "edit.soy.xyz/verse/png")
            .replace("metadata.soy.xyz/images", "edit.soy.xyz/png/images")
        );
      }}
      className="special_modal align-items-center justify-content-center"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="sale-modal modal-90h modal-90w"
      centered
    >
      <Modal.Header>
        <div className="float-right">
          <img
            alt=""
            style={{ transition: "none" }}
            src={crossBig}
            className="Cross-big float-right"
            onClick={() => {
              props.setShow(false);
            }}
          />
        </div>
        <Row className="align-items-center justify-content-center w-100">
          <Col md="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={info}
              className="info-img"
            />
          </Col>
          <Col md="auto">
            <div className="pfp-modal-title"> Download the version</div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center justify-content-center h-100">
          <Row className="align-items-center justify-content-center mb-3">
            <Stage
              width={500}
              height={500}
              ref={stageRef}
              className="pfp-banner-canvas pfp-banner-canvas2"
            >
              <Layer width={2000} height={2000}>
                <URLImage
                  src={
                    currentBg ||
                    (props.selected.isVerse
                      ? "/editor/verse/blue_fishes.jpeg"
                      : "/editor/butterflys.jpg")
                  }
                  x={0}
                  y={0}
                  width={500}
                  height={500}
                />
                {props.selected.isVerse && (
                  <URLImage
                    src={currentFrame}
                    x={0}
                    y={0}
                    width={500}
                    height={500}
                  />
                )}
                {!props.selected.isVerse && (
                  <URLImage
                    src={currentFrame}
                    x={
                      imagesArray(props.selected.isVerse).indexOf(currentBg) > 1
                        ? 50
                        : 0
                    }
                    y={
                      imagesArray(props.selected.isVerse).indexOf(currentBg) > 1
                        ? 50
                        : 0
                    }
                    width={
                      imagesArray(props.selected.isVerse).indexOf(currentBg) > 1
                        ? 400
                        : 500
                    }
                    height={
                      imagesArray(props.selected.isVerse).indexOf(currentBg) > 1
                        ? 400
                        : 500
                    }
                  />
                )}
              </Layer>
            </Stage>
          </Row>
          <Row className="align-items-center justify-content-center mb-1">
            <Col md="auto">
              <div className="pfp-modal-title">
                {" "}
                Pick the background you like
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center mb-3">
            <SimpleCarousel
              images={imagesArray(props.selected.isVerse)}
              didSelect={(selected) => {
                setBg(selected);
              }}
            />
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md="auto">
              <Button
                className="main-action-button modal-button-margin"
                onClick={saveHDJPGFile}
              >
                JPG
              </Button>{" "}
            </Col>
            <Col md="auto">
              <Button
                className="main-action-button modal-button-margin"
                onClick={saveHDPNGFile}
              >
                PNG without background
              </Button>{" "}
            </Col>
          </Row>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default PFPModal;
