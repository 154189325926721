import { useState, useRef } from "react";
import { Row, Image, Col } from "react-bootstrap";
import share from "../assets/share-icon.webp";
import checkmark from "../assets/checkmark.png";
import edit from "../assets/edit-icon.webp";
import exportAsImage from "../model/ExportImage";
import html2canvas from "html2canvas";
import logo from "../assets/soy_logo.png";
import SoyShare from "./SoyShare";

function StoryCard({ item, editable, onEdit }) {
  const [isCopied, setIsCopied] = useState(false);
  const [image, SetImage] = useState(false);
  const exportRef = useRef();
  const [modalShow, setModalShow] = useState(false);

  const handleDownloadImage = async () => {
    const element = exportRef.current;
    const canvas = await html2canvas(element, {
      backgroundColor: "none",
      logging: true,
      useCORS: true, //to enable cross origin perms
    });

    const data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      SetImage(data);
      setModalShow(true);
    } else {
      window.open(data);
    }
  };

  const truncateOwner = (addr) => {
    if (addr.length > 5) {
      return addr.substring(0, 6) + "..." + addr.substring(addr.length - 5);
    }
    return addr;
  };

  return (
    <div className="soy-card-wrap" ref={exportRef}>
      <SoyShare
        image={image}
        id={item.id}
        title={item.title}
        show={modalShow}
        setShow={setModalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="soy-story-card">
        {" "}
        <Row className="">
          <Col>
            <div className="d-flex flex-row-reverse">
              {" "}
              <div>
                <div className="tooltip">
                  <span className="tooltiptext">Tooltip text</span>
                </div>{" "}
                <img
                  data-html2canvas-ignore="true"
                  alt=""
                  hidden={isCopied}
                  style={{ transition: "opacity 1s ease-in-out" }}
                  src={share}
                  className="soy-share-icon float-right"
                  onClick={() => {
                    handleDownloadImage();
                    // handleCopyClick(`https://edit.soy.xyz/read/${item.id}`);
                  }}
                />{" "}
                <img
                  data-html2canvas-ignore="true"
                  alt=""
                  hidden={!isCopied}
                  style={{ transition: "opacity 1s ease-in-out" }}
                  src={checkmark}
                  className="soy-share-icon float-right"
                />{" "}
              </div>
              {editable && (
                <img
                  data-html2canvas-ignore="true"
                  alt=""
                  style={{ transition: "none" }}
                  src={edit}
                  className="soy-edit-icon float-right"
                  onClick={onEdit}
                />
              )}
            </div>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-center mb-1">
          <Image
            className="soy-story-img-main"
            src={`https://ik.imagekit.io/gwqy9u8ik${
              process.env.REACT_APP_SOY_DARK ? "/dark" : ""
            }/${item.id}.png?tr=w-300,h-300`}
            alt="loading..."
            roundedCircle
            crossOrigin="anonymous"
          />
        </Row>
        <Row className="align-items-center justify-content-center  text-center">
          <span className="soy-story-title">{item.title}</span>
        </Row>
        <Row className="align-items-center justify-content-center">
          <span className="soy-story-sub-title">
            {new Date(item.when).toLocaleDateString()}, by{" "}
            <a
              href={`https://opensea.io/${item.owner}`}
              target="_blank"
              className="addr-link"
            >
              {truncateOwner(item.owner)}
            </a>
          </span>
        </Row>
        <Row className="align-items-center justify-content-center">
          <span className="soy-story-text overflow-auto">{item.text}</span>
        </Row>
      </div>
    </div>
  );
}

export default StoryCard;
