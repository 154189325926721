import { ref, orderByChild, equalTo, query, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDatabase } from "firebase/database";

export const firebaseConfig = {
  apiKey: "AIzaSyAZZOoXIwD1ryCShNHwv7xCqjDn-qTPg7k",
  authDomain: "soystories-5224c.firebaseapp.com",
  databaseURL:
    "https://soystories-5224c-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "soystories-5224c",
  storageBucket: "soystories-5224c.appspot.com",
  messagingSenderId: "497303069525",
  appId: "1:497303069525:web:079e16f2630be805b305dc",
  measurementId: "G-XS423RFCZ9",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);
const database = getDatabase(app);

export async function readData(address) {
  return new Promise((resolve, reject) => {
    console.log(`${address}`);
    const allStories = query(
      process.env.REACT_APP_SOY_DARK ? ref(database, "dss/") : ref(database),
      orderByChild("owner"),
      equalTo(`${address}`.toLowerCase())
    );
    onValue(
      allStories,
      (snapshot) => {
        resolve(snapshot.val());
      },
      (error) => {
        console.log(error);
        reject(error);
      }
    );
  });
}

export async function updateStory(tokenId, title, text) {
  console.log(!!process.env.REACT_APP_SOY_DARK);
  const updateStory = httpsCallable(functions, "updateStory");
  await updateStory({
    tokenId,
    isDark: !!process.env.REACT_APP_SOY_DARK,
    title,
    text,
    updated: new Date(),
  });
}
