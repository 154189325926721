import React from "react";
import crossBig from "../assets/cross-big.svg";
import { useRef } from "react";
import { Row, Button, Modal, Col } from "react-bootstrap";
import info from "../assets/info.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function SoyShare(props) {
  const handleDownloadImage = async () => {
    const data = props.image;
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = data;
      link.download = `soy_story${props.id}.png`;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="align-items-center justify-content-center soy-picker-modal"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="sale-modal modal-60h modal-90w"
      centered
    >
      <Modal.Header>
        <div className="float-right">
          <img
            alt=""
            style={{ transition: "none" }}
            src={crossBig}
            className="Cross-big float-right"
            onClick={() => {
              props.setShow(false);
            }}
          />
        </div>
        <Row className="align-items-center justify-content-center w-100">
          <Col md="auto" xs="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={info}
              className="info-img"
            />
          </Col>
          <Col md="auto" xs="auto">
            <div className="pfp-modal-title"> Share your Story </div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <div className="text-divider">Step 1</div>
        </Row>
        <Row className="justify-content-center mt-3">
          <img src={props.image} className="soy-share-image" />
        </Row>
        <Row md="auto" xs="auto" className="justify-content-center w-100 mb-3">
          <Col className="justify-content-center align-items-center">
            {" "}
            <div className="justify-content-center align-items-center">
              {" "}
              <Button className="main-button " onClick={handleDownloadImage}>
                <FontAwesomeIcon icon={faDownload} /> Save Image
              </Button>{" "}
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center mt-2">
          <div className="text-divider">Step 2</div>
        </Row>
        <Row md="auto" xs="auto" className="justify-content-center w-100 mt-5">
          <Col className="justify-content-center align-items-center">
            {" "}
            <div className="justify-content-center align-items-center">
              <Button
                className="main-button "
                href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
                  `Check out my ${props.title} \n#ShadyStories`
                )}&url=${encodeURI(
                  (process.env.REACT_APP_SOY_DARK
                    ? "https://dark.edit.soy.xyz/read/"
                    : "https://edit.soy.xyz/read/") + props.id
                )}`}
              >
                <FontAwesomeIcon icon={faTwitter} /> Share
              </Button>{" "}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default SoyShare;
