import React from "react";
import crossBig from "../assets/cross-big.svg";
import { Row, Button, Modal, Col, Form, Image } from "react-bootstrap";

function StoryModal(props) {
  const [title, setTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [width, setWidth] = React.useState(window.innerWidth);
  let isMobile = width <= 768;

  React.useEffect(() => {
    setTitle(props.title);
    setText(props.text);
  }, [props.user, props.text]);

  function onTitleChange(event) {
    setTitle(event.target.value);
  }

  function onTextChange(event) {
    setText(event.target.value);
  }

  return (
    <Modal
      {...props}
      size="lg"
      className="align-items-center justify-content-center soy-picker-modal"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="sale-modal modal-60h modal-90w"
      centered
    >
      <Modal.Header>
        <div className="float-right">
          <img
            alt=""
            style={{ transition: "none" }}
            src={crossBig}
            className="Cross-big float-right"
            onClick={() => {
              props.setShow(false);
            }}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center">
          <Image
            className="soy-story-img"
            src={`${
              props.item.image
                ? props.item.image
                    .replace(".jpg", ".png")
                    .replace(
                      "metadata.soy.xyz/images",
                      "edit.soy.xyz/png/images"
                    )
                : ""
            }`}
            alt="loading..."
            roundedCircle
            crossOrigin="anonymous"
          />
        </Row>
        <Row className="justify-content-center">
          <Form className="soy-story-form">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label className="story-title-edit-label">Title</Form.Label>
              <Form.Control
                type="text"
                maxLength={50}
                value={title || props.title}
                onChange={onTitleChange}
                placeholder={`${props.item.name?.split("-")[0].trim()}'s Story`}
                className="story-title-edit"
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label className="soy-info">Story</Form.Label>
              <Form.Control
                as="textarea"
                rows={isMobile ? 22 : 30}
                onChange={onTextChange}
                value={text || props.text}
                className="story-body-edit"
              />
            </Form.Group>
          </Form>
        </Row>
        <Row className="justify-content-center">
          <Col md="auto" xs="auto">
            <Button
              disabled={!!!text.length}
              className="main-action-button "
              onClick={() => {
                props.didSave(
                  title || `${props.item.name?.split("-")[0].trim()}'s Story`,
                  text
                );
              }}
            >
              Save
            </Button>{" "}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default StoryModal;
