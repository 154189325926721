import dotenv from "dotenv";
import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.css";
import App from "./App";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

if (process.env.REACT_APP_SOY_DARK) {
  import("./index-dark.css").then(() => {});
} else {
  import("./index.css").then(() => {});
}

dotenv.config({ silent: process.env.NODE_ENV === "production" });

Sentry.init({
  dsn: "https://28dcceeaec2d485398cc7415fe9ab734@o1009996.ingest.sentry.io/5974392",
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
