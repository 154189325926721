import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import info from "../assets/info.svg";
import checkmark from "../assets/checkmark.png";
import StoryCard from "../components/StoryCard";
import { useLocation, useParams } from "react-router-dom";

const fetchRetry = require("fetch-retry")(fetch);

function StoriesPage({ isMobile, addr, hideCopy }) {
  const [stories, setStories] = useState([]);
  const [isCopied2, setIsCopied2] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { _addr } = useParams();

  useEffect(() => {
    const params = {
      method: "GET",
      retries: 3,
      crossDomain: true,
      retryDelay: 200,
      // mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    // declare the data fetching function
    const fetchData = async () => {
      const updateStories = (data) => {
        if (!data) {
          setIsLoading(false);
          return;
        }
        setStories(
          Object.keys(data).map(function (key) {
            return { ...data[key], id: key };
          })
        );
        setIsLoading(false);
      };
      if (addr === "ones") {
        const result = await fetchRetry(
          `https://us-central1-soystories-5224c.cloudfunctions.net/getStories/ones`,
          params
        );
        const metadata = await result.json();
        updateStories(metadata);
      } else if (addr === "dark") {
        const result = await fetchRetry(
          `https://us-central1-soystories-5224c.cloudfunctions.net/getStories/dark`,
          params
        );
        const metadata = await result.json();
        updateStories(metadata);
      } else if (Number.isInteger(Number(addr))) {
        const result = await fetchRetry(
          `https://us-central1-soystories-5224c.cloudfunctions.net/getStories/item${
            process.env.REACT_APP_SOY_DARK ? "-dark" : ""
          }/${addr}`,
          params
        );
        const metadata = await result.json();
        const res = {};
        res[addr] = metadata;
        updateStories(res);
      } else {
        const result = await fetchRetry(
          `https://us-central1-soystories-5224c.cloudfunctions.net/getStories/addr${
            process.env.REACT_APP_SOY_DARK ? "-dark" : ""
          }/${addr}`,
          params
        );
        const metadata = await result.json();
        updateStories(metadata);
      }
    };

    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, []);

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick2 = (copyText) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied2(true);
        setTimeout(() => {
          setIsCopied2(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const truncateOwner = (addr) => {
    if (addr.length > 5) {
      return addr.substring(0, 6) + "..." + addr.substring(addr.length - 5);
    }
    return addr;
  };

  return (
    <div className="mt-3">
      <Row hidden={!isLoading} className="mt-5 list-soy">
        <div className="pfp-modal-title"> Loading...</div>
      </Row>
      <Row
        hidden={isLoading || hideCopy}
        className="align-items-center justify-content-center mb-4 mt-5"
      >
        <Col hidden={isMobile} md="auto">
          <img
            alt=""
            style={{ transition: "none" }}
            src={info}
            className="info-img"
          />
        </Col>
        <Col md="auto">
          <div className="pfp-modal-title">
            Want to share entire stories page?
          </div>
        </Col>
        <Col xs="auto" md="auto" className="mr-3">
          <div className="copy-link">
            {" "}
            <span
              hidden={isCopied2}
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleCopyClick2(
                  process.env.REACT_APP_SOY_DARK
                    ? `https://dark-edit.soy.xyz/read/${addr}`
                    : `https://edit.soy.xyz/read/${addr}`
                )
              }
            >
              Copy Link
            </span>
            <img
              alt=""
              hidden={!isCopied2}
              style={{ transition: "opacity 1s ease-in-out" }}
              src={checkmark}
              className="soy-share-icon-flat"
            />{" "}
          </div>
        </Col>
      </Row>
      <Row
        hidden={isLoading}
        className="align-items-center justify-content-center mb-1"
      >
        {stories.map((item, index) => {
          return (
            <Row
              key={index}
              className="align-items-center justify-content-center mb-5"
            >
              <StoryCard item={item} editable={false} />
            </Row>
          );
        })}
      </Row>
    </div>
  );
}

export default StoriesPage;
