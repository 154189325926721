import "./App.css";
import logo from "./assets/soy_logo.png";
import crossBig from "./assets/cross-big.svg";
import metamask from "./assets/metamask.svg";
import walletConnect from "./assets/walletc.svg";
import coinbaseWallet from "./assets/coibaseWallet.webp";
import Web3 from "web3";
import WalletLink from "walletlink";
import detectEthereumProvider from "@metamask/detect-provider";
import { getTokensData, getTokensDataVerse } from "./model/Blockchain";
import { useEffect, useState, useCallback } from "react";
import {
  Navbar,
  Container,
  Row,
  Col,
  Button,
  Alert,
  Modal,
  Nav,
  Offcanvas,
} from "react-bootstrap";
import WalletConnectProvider from "@walletconnect/web3-provider";
import * as Sentry from "@sentry/react";
import { Route, Link, Redirect, Switch } from "react-router-dom";
import PFPPage from "./pages/PFP";
import BannerPage from "./pages/Banner";
import StoriesPage from "./pages/Stories";
import ReadStoriesPage from "./pages/ReadStories";
import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { readData, firebaseConfig } from "./model/FirebaseHooks";
import { useLocation } from "react-router-dom";

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const functions = getFunctions(app);

const fetchRetry = require("fetch-retry")(fetch);

function App() {
  const location = useLocation();

  const [nfts, setNfts] = useState([]);
  const [stories, setStories] = useState([]);

  const [currentAccount, setCurrentAccount] = useState("");
  const [isLogged, setIsLogged] = useState(false);
  const [modalConnectShow, setModalConnectShow] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;
  const [currentProvider, setProvider] = useState();
  const [page, setPage] = useState("stories");
  const [messages, setMessage] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showNav, setShowNav] = useState(false);

  const toggleOffCanvas = () => {
    setShowNav((show) => !show);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const SignIn = useCallback(async () => {
    setModalConnectShow(true);
  }, []);

  const getNfts = async (address) => {
    const params = {
      method: "GET",
      retries: 3,
      crossDomain: true,
      retryDelay: 200,
      // mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      const tokensData = await getTokensData(address);
      const tokensDataVerse = process.env.REACT_APP_SOY_DARK
        ? []
        : (await getTokensDataVerse(address)).map((el) => {
            return { ...el, isVerse: true };
          });

      const allMeta = await Promise.all(
        tokensDataVerse.concat(tokensData).map(async (tokenData) => {
          const result = await fetchRetry(
            process.env.REACT_APP_SOY_DARK
              ? `https://metadata.soy.xyz/dss/${tokenData.tokenId}`
              : tokenData.isVerse
              ? `https://edit.soy.xyz/verse/json/${tokenData.tokenId}`
              : `https://edit.soy.xyz/json/${tokenData.tokenId}`,

            params
          );

          const metadata = await result.json();
          return {
            ...tokenData,
            ...metadata,
          };
        })
      );
      const stories = await readData(address);
      if (stories) {
        setStories(
          Object.keys(stories).map(function (key) {
            return { ...stories[key], id: key };
          })
        );
      }

      setNfts(allMeta);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      Sentry.captureException(err);
    }
  };

  const authenticate = async (address, provider) => {
    try {
      const addMessage = httpsCallable(functions, "requestMessage");

      const messageResponse = await addMessage({
        address,
        chain: 1,
      });
      const message = messageResponse.data.message;
      const web3 = new Web3(provider);
      const signature = await web3.eth.personal.sign(message, address);

      const issueToken = httpsCallable(functions, "issueToken");

      const tokenResponse = await issueToken({
        message,
        signature,
      });
      const token = tokenResponse.data.token;
      const auth = getAuth();
      await signInWithCustomToken(auth, token);
    } catch (e) {
      console.log(e);
    }
  };

  const connectEth = async (provider, isMetamask) => {
    try {
      console.log(provider);
      const web3 = new Web3(provider);
      if (!provider) {
        setMessage([
          {
            head: "Wallet not found",
            body: `Please install the MetaMask!`,
            variant: "warning",
          },
        ]);
      } else {
        const address = await ConnectWallet(web3, isMetamask);
        if (!!address) {
          setMessage((messages) => [
            ...messages.filter((el) => el.body !== `Address: ${address}`),
            {
              head: "👋 You are logged in",
              body: `Address: ${address}`,
              variant: "success",
            },
          ]);
          await authenticate(address, provider);
          await getNfts(address);
          window.ethereum.on("accountsChanged", handleAccountsChanged);
        }
      }
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  function openMetaMaskUrl(url) {
    const a = document.createElement("a");
    a.href = url;
    a.target = "_self";
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  const connectMetamask = async () => {
    setModalConnectShow(false);

    const provider = await detectEthereumProvider();
    setProvider(provider);
    await connectEth(provider, true);
  };

  const connectWalletConnect = async () => {
    setModalConnectShow(false);
    const provider = new WalletConnectProvider({
      infuraId: process.env.REACT_APP_INFURA_APP_ID,
    });

    try {
      await provider.enable();
      setProvider(provider);
      await connectEth(provider, false);
    } catch (e) {
      console.error(e);
    }
  };

  const connectWalletLink = async () => {
    setModalConnectShow(false);
    const walletLink = new WalletLink({
      appName: "SOY Creator",
      appLogoUrl: "/favicon.ico",
      darkMode: true,
    });

    // Initialize a Web3 Provider object
    const provider = walletLink.makeWeb3Provider(
      `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_APP_ID}`,
      1
    );

    try {
      setProvider(provider);
      const accounts = await provider.request({
        method: "eth_requestAccounts",
      });
      setIsLogged(true);
      setCurrentAccount(accounts[0]);
      await getNfts(accounts[0]);
      window.ethereum.on("accountsChanged", handleAccountsChanged);
    } catch (e) {
      console.error(e);
    }
  };

  const ConnectWallet = async (web3, isMetamask) => {
    try {
      const accounts = isMetamask
        ? await window.ethereum.request({ method: "eth_requestAccounts" })
        : await web3.eth.getAccounts();
      setIsLogged(true);
      setCurrentAccount(accounts[0]);
      return accounts[0];
    } catch (err) {
      if (err.code === 4001) {
        // EIP-1193 userRejectedRequest error
        // If this happens, the user rejected the connection request.
        console.info("Please connect to MetaMask.");
        setMessage((messages) => [
          ...messages,
          {
            head: "User Rejected Request",
            body: "Please connect to a MetaMask.",
            variant: "info",
          },
        ]);
      } else if (err.code === -32002) {
        console.info("Please unlock MetaMask.");
        setMessage((messages) => [
          ...messages,
          {
            head: "User Request Pending",
            body: "Please unlock a MetaMask and try agin.",
            variant: "info",
          },
        ]);
      } else {
        console.error(err);
        setMessage((messages) => [
          ...messages,
          { head: "Error", body: err.message, variant: "info" },
        ]);
      }
    }
  };

  const handleAccountsChanged = async (accounts) => {
    if (accounts.length === 0) {
      // MetaMask is locked or the user has not connected any accounts
      setMessage((messages) => [
        ...messages,
        {
          head: "User Rejected Request",
          body: "Please connect to MetaMask.",
          variant: "info",
        },
      ]);
    } else if (accounts[0] !== currentAccount) {
      setCurrentAccount(accounts[0]);
      await getNfts(accounts[0]);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    // window.onbeforeunload = function () {
    //   return "Prevent reload";
    // };
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const SignOut = async () => {
    setIsLogged(false);
    setCurrentAccount("");
    try {
      await currentProvider.disconnect();
    } catch {}
  };

  const shortAddr = () => {
    return `${currentAccount.substr(0, 4)}...${currentAccount.substring(
      currentAccount.length - 4,
      currentAccount.length
    )}`;
  };

  const Message = (props) => {
    const [show, setShow] = useState(true);

    const close = () => {
      setShow(false);
      setMessage(messages.filter((item, index) => index !== props.id));
    };

    if (show) {
      return (
        <Alert className="soy-alert" onClose={close} dismissible>
          <Alert.Heading>{props.head}</Alert.Heading>
          <p className="alert-soy">{props.body}</p>
        </Alert>
      );
    } else {
      return <></>;
    }
  };

  function ConectModal(props) {
    return (
      <Modal
        {...props}
        className="align-items-center justify-content-center"
        aria-labelledby="contained-modal-title-vcenter"
        dialogClassName="trusted-modal"
        centered
      >
        <Modal.Body>
          <Row
            // hidden={isMobile}
            className="align-items-center justify-content-center h-100 row-border wallet-connect-hover"
            onClick={connectMetamask}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={metamask}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">MetaMask</div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect to your MetaMask Wallet
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover row-border"
            onClick={connectWalletConnect}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={walletConnect}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Wallet Connect {isMobile ? "& Metamask" : ""}
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Scan with WalletConnect to connect
                </div>
              </Row>
            </Col>
          </Row>
          <Row
            className="align-items-center justify-content-center h-100 wallet-connect-hover"
            onClick={connectWalletLink}
          >
            <Col className="align-items-center justify-content-center ">
              <Row className="align-items-center justify-content-center mt-3">
                <img
                  alt=""
                  style={{ transition: "none" }}
                  src={coinbaseWallet}
                  className="metamask-icon"
                />
              </Row>
              <Row className="align-items-center justify-content-center mt-2">
                <div className="wallet-connect-title text-center">
                  Coinbase Wallet
                </div>
              </Row>
              <Row className="align-items-center justify-content-center mb-5">
                <div className="wallet-connect-sub text-center">
                  Connect your browser or mobile app
                </div>
              </Row>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {/* <div hidden={!isMobile}>
        <MobilePlaceholder />
      </div>
      <div hidden={isMobile}>
        <Navbar className="navbar-expand-md" variant="dark">
          <div className="top-connect-box">
            <Row>
              <Col md="auto">
                <img
                  alt=""
                  src={logo}
                  width="80"
                  height="80"
                  className="d-inline-block align-top"
                />{" "}
              </Col>
              <Col md="auto" className="justify-content-center align-items-center text-center">
                <Row className="studio-title justify-content-center align-items-center">
                  SHADIES EDITOR
                </Row>
              </Col>
            </Row>
          </div>
          <Row className="mx-auto order-0 justify-content-center align-items-center ">
            <Col className="justify-content-center align-items-center ">
              {" "}
              <Button
                disabled={page === "pfp"}
                className="menu-button"
                onClick={() => {
                  setPage("pfp");
                }}
      </div> */}
      <div>
        <Navbar
          collapseOnSelect
          expand="md"
          className="navbar-expand-md"
          variant="dark"
        >
          {isMobile && (
            <Container fluid>
              <Navbar.Brand
                as="div"
                className="justify-content-center align-items-center align-middle"
              >
                {" "}
                <a href="https://soy.xyz" rel="noreferrer">
                  <img
                    alt=""
                    src={logo}
                    width="100"
                    height="71"
                    className="d-inline-block align-top soy-logo-img"
                  />
                </a>
                {process.env.REACT_APP_SOY_DARK && (
                  <span className="nav-bar-brand-title-dark ">DARK </span>
                )}
                <span className="nav-bar-brand-title">CREATOR</span>
              </Navbar.Brand>
              {!process.env.REACT_APP_SOY_DARK && (
                <>
                  {isLogged && (
                    <>
                      <Navbar.Toggle
                        aria-controls={`offcanvasNavbar-expand-sm`}
                        onClick={toggleOffCanvas}
                      />

                      <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand-sm`}
                        aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
                        placement="end"
                        variant="light"
                        className="text-bg-dark"
                        show={showNav}
                        onHide={toggleOffCanvas}
                      >
                        <Offcanvas.Header closeButton closeVariant="white">
                          <Offcanvas.Title
                            id={`offcanvasNavbarLabel-expand-sm`}
                            className="studio-title"
                          >
                            SOY Creator
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Nav className="justify-content-end flex-grow-1 pe-3 text-bg-dark">
                            <Link
                              to="/stories"
                              className="nav-link"
                              onClick={toggleOffCanvas}
                            >
                              Stories
                            </Link>
                            <Link
                              to="/pfp"
                              className="nav-link"
                              onClick={toggleOffCanvas}
                            >
                              PFP
                            </Link>
                            <Link
                              to="/banner"
                              className="nav-link"
                              onClick={toggleOffCanvas}
                            >
                              Banner
                            </Link>
                          </Nav>
                        </Offcanvas.Body>
                      </Navbar.Offcanvas>
                    </>
                  )}
                </>
              )}
            </Container>
          )}
          {!isMobile && (
            <>
              <Navbar.Brand
                as="div"
                className="justify-content-center align-items-center align-middle"
              >
                {" "}
                <a href="https://soy.xyz" rel="noreferrer">
                  <img
                    alt=""
                    src={logo}
                    width="100"
                    height="71"
                    className="d-inline-block align-top soy-logo-img"
                  />
                </a>
                {process.env.REACT_APP_SOY_DARK && (
                  <span className="nav-bar-brand-title-dark ">DARK </span>
                )}
                <span className="nav-bar-brand-title">CREATOR</span>
              </Navbar.Brand>
              <Row className="mx-auto order-0 justify-content-center align-items-center ">
                <Col className="justify-content-center align-items-center ">
                  {" "}
                  <Button
                    hidden={!isLogged || process.env.REACT_APP_SOY_DARK}
                    disabled={page === "stories"}
                    className="menu-button"
                    onClick={() => {
                      setPage("stories");
                    }}
                  >
                    <Link to="/stories">Stories</Link>
                  </Button>{" "}
                </Col>
                <Col className="justify-content-center align-items-center ">
                  {" "}
                  <Button
                    hidden={!isLogged || process.env.REACT_APP_SOY_DARK}
                    disabled={page === "pfp"}
                    className="menu-button"
                    onClick={() => {
                      setPage("pfp");
                    }}
                  >
                    <Link to="/pfp">PFP</Link>
                  </Button>{" "}
                </Col>
                <Col className="justify-content-center align-items-center ">
                  {" "}
                  <Button
                    hidden={!isLogged || process.env.REACT_APP_SOY_DARK}
                    disabled={page === "banner"}
                    className="menu-button"
                    onClick={() => {
                      setPage("banner");
                    }}
                  >
                    <Link to="/banner">Banner</Link>
                  </Button>{" "}
                </Col>
              </Row>
              <div className="float-right top-connect-box">
                <Row>
                  <Col hidden={isMobile} className="mr-3">
                    <div className="balance-top">
                      {[].concat.apply([], nfts).length} 👻
                    </div>
                  </Col>
                  {!isLogged && (
                    <Col>
                      <Button
                        className="connect-top"
                        disabled={isLogged}
                        onClick={SignIn}
                      >
                        Connect
                      </Button>{" "}
                    </Col>
                  )}
                  {isLogged && !isMobile && (
                    <Col>
                      <Button
                        className="connect-top"
                        disabled={isLogged}
                        onClick={SignIn}
                      >
                        {shortAddr()}
                      </Button>{" "}
                    </Col>
                  )}
                  <Col hidden={isMobile}>
                    <img
                      alt=""
                      hidden={!isLogged}
                      src={crossBig}
                      className="Cross-big-top"
                      onClick={SignOut}
                    />
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Navbar>

        <div className="message-list">
          {messages.map((item, i) => (
            <Message
              head={item.head}
              body={item.body}
              variant={item.variant}
              id={i}
              key={i}
              nohide={item.nohide}
            />
          ))}
        </div>
        <ConectModal
          show={modalConnectShow}
          onHide={() => setModalConnectShow(false)}
        />

        <Container fluid>
          {!isLogged && !location.pathname.includes("/read/") && (
            <Row className="h-100 z-high">
              <Col className="align-items-center justify-content-center h-100  ">
                <Row
                  md="auto"
                  className="justify-content-center w-100 main-box"
                >
                  <Button
                    className="main-button main-connect-button"
                    onClick={SignIn}
                  >
                    Connect
                  </Button>{" "}
                  {location.pathname.includes("/stories") && (
                    <Row
                      md="auto"
                      className="justify-content-center w-100 main-box"
                    >
                      <ReadStoriesPage
                        isMobile={isMobile}
                        addr={process.env.REACT_APP_SOY_DARK ? "dark" : "ones"}
                        hideCopy={true}
                      />
                    </Row>
                  )}
                </Row>
              </Col>
            </Row>
          )}
          <Switch>
            <Route
              path="/read/:addr"
              render={() => (
                <ReadStoriesPage
                  hidden={isLoading}
                  isMobile={isMobile}
                  addr={location.pathname.split("/").slice(-1)[0]}
                />
              )}
            />
            {isLogged && (
              <>
                <Row hidden={!isLoading} className="mt-5 list-soy">
                  <div className="pfp-modal-title"> Loading...</div>
                </Row>

                <div hidden={isLoading}>
                  {!process.env.REACT_APP_SOY_DARK && (
                    <>
                      {" "}
                      <Route exact path="/">
                        <Redirect to="/pfp" />
                      </Route>
                      <Route
                        path="/stories"
                        render={() => (
                          <StoriesPage
                            isMobile={isMobile}
                            addr={currentAccount}
                            hidden={isLoading}
                            items={nfts}
                            stories={stories}
                          />
                        )}
                      />
                      <Route
                        path="/pfp"
                        render={() => (
                          <PFPPage hidden={isLoading} items={nfts} />
                        )}
                      />
                      <Route
                        path="/banner"
                        render={() => (
                          <BannerPage hidden={isLoading} flowtys={nfts} />
                        )}
                      />
                    </>
                  )}
                  {process.env.REACT_APP_SOY_DARK && (
                    <>
                      <Route
                        path="/"
                        render={() => (
                          <StoriesPage
                            isMobile={isMobile}
                            addr={currentAccount}
                            hidden={isLoading}
                            items={nfts}
                            stories={stories}
                          />
                        )}
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </Switch>
        </Container>
      </div>
    </>
  );
}

export default App;
