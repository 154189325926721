import { useState, useEffect, useRef } from "react";
import PFPModal from "../components/PFPModal";
import { Row, Col } from "react-bootstrap";
import info from "../assets/info.svg";
import NftList from "../components/NftList";

function PFP({ items }) {
  const [modalShow, setModalShow] = useState(false);

  const flowtysListRef = useRef();
  const [selected, setSelected] = useState([]);
  return (
    <div>
      <PFPModal
        show={modalShow}
        selected={selected && selected.length ? selected[0] : {}}
        setShow={setModalShow}
        onHide={() => setModalShow(false)}
      />
      <Row className="align-items-center justify-content-center mb-4 mt-5">
        <Col md="auto" sm="auto" xs="auto">
          <img
            alt=""
            style={{ transition: "none" }}
            src={info}
            className="info-img"
          />
        </Col>
        <Col md="auto" sm="auto" xs="auto">
          <div className="pfp-modal-title">
            {" "}
            Click on a SOY to download its JPG/PNG and change background
          </div>
        </Col>
      </Row>
      <Row className="mt-5 justify-content-center list-soy">
        <NftList
          ref={flowtysListRef}
          limit={1}
          items={items}
          didSelect={(selected) => {
            setSelected(selected);
            if (selected.length) {
              setModalShow(true);
            }
          }}
        />
      </Row>
    </div>
  );
}

export default PFP;
