import { useState, useEffect } from "react";
import PickerModal from "../components/SoyPicker";
import StoryModal from "../components/StoryEdit";
import { Row, Col, Button, Image } from "react-bootstrap";
import info from "../assets/info.svg";
import { updateStory } from "../model/FirebaseHooks";
import checkmark from "../assets/checkmark.png";
import StoryCard from "../components/StoryCard";

function StoriesPage({ isMobile, addr, items, stories }) {
  const [selectedSoy, setSelectedSoy] = useState({});
  const [modalShow, setModalShow] = useState(false);
  const [modalPickerShow, setModalPickerShow] = useState(false);
  const [isCopied2, setIsCopied2] = useState(false);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [_stories, setStories] = useState([]);

  useEffect(() => {
    setStories(stories);
  }, [stories]);

  const pickSoy = () => {
    setModalPickerShow(true);
  };

  // This is the function we wrote earlier
  async function copyTextToClipboard(text) {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  }

  // onClick handler function for the copy button
  const handleCopyClick2 = (copyText) => {
    // Asynchronously call copyTextToClipboard
    copyTextToClipboard(copyText)
      .then(() => {
        // If successful, update the isCopied state value
        setIsCopied2(true);
        setTimeout(() => {
          setIsCopied2(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <StoryModal
        show={modalShow}
        item={selectedSoy}
        title={title}
        text={text}
        setShow={setModalShow}
        didSave={(title, text) => {
          updateStory(selectedSoy.id, title, text);
          setModalShow(false);
          setTitle("");
          setText("");
          if (_stories.find((el) => el.id === selectedSoy.id)) {
            setStories(
              _stories.map((obj) => {
                if (obj.id === selectedSoy.id) {
                  return { ...obj, title, text };
                }

                return obj;
              })
            );
          } else {
            setStories(
              [
                {
                  id: selectedSoy.id,
                  title,
                  text,
                  owner: addr,
                  when: new Date().getTime(),
                },
              ].concat(_stories)
            );
          }
        }}
      />
      <PickerModal
        show={modalPickerShow}
        items={items.filter(
          (el) => !_stories.find((item) => `${el.id}` === `${item.id}`)
        )}
        setShow={setModalPickerShow}
        onHide={() => setModalPickerShow(false)}
        didSelect={(item) => {
          setModalPickerShow(false);
          setSelectedSoy(item);
          setModalShow(true);
        }}
      />
      <Row className="align-items-center justify-content-center mb-4 mt-5">
        <Col xs="auto" md="auto">
          <img
            alt=""
            style={{ transition: "none" }}
            src={info}
            className="info-img"
          />
        </Col>
        <Col md="auto" xs="auto">
          <div className="pfp-modal-title">
            Want to share your entire stories page?
          </div>
        </Col>
        <Col md="auto" xs="auto" className="mr-3">
          <div className="copy-link">
            {" "}
            <span
              hidden={isCopied2}
              style={{ cursor: "pointer" }}
              onClick={() =>
                handleCopyClick2(
                  process.env.REACT_APP_SOY_DARK
                    ? `https://dark-edit.soy.xyz/read/${addr}`
                    : `https://edit.soy.xyz/read/${addr}`
                )
              }
            >
              Copy Link
            </span>
            <img
              alt=""
              hidden={!isCopied2}
              style={{ transition: "opacity 1s ease-in-out" }}
              src={checkmark}
              className="soy-share-icon float-right"
            />{" "}
          </div>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mb-5">
        <Col
          md="auto"
          xs="auto"
          className="justify-content-center align-items-center"
        >
          <Button className="main-action-button " onClick={pickSoy}>
            Create New Story
          </Button>{" "}
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mb-1">
        {_stories.map((item, index) => {
          return (
            <Row className="align-items-center justify-content-center mb-5">
              <StoryCard
                item={item}
                editable={true}
                onEdit={() => {
                  setTitle(item.title);
                  setText(item.text);
                  setSelectedSoy(
                    items.find((el) => `${el.id}` === `${item.id}`)
                  );
                  setModalShow(true);
                }}
              />
            </Row>
          );
        })}
      </Row>
    </div>
  );
}

export default StoriesPage;
