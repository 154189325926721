import React from "react";
import crossBig from "../assets/cross-big.svg";
import { useRef } from "react";
import { Row, Button, Modal, Col } from "react-bootstrap";
import { saveAs } from "file-saver";
import URLImage from "./URLImage";
import { Stage, Layer } from "react-konva";
import twtPfp from "../assets/twitter-pfp-modified.png";
import ItemsList from "./NftList";
import info from "../assets/info.svg";

function PickerModal(props) {
  const stageRef = useRef(null);

  return (
    <Modal
      {...props}
      size="lg"
      className="align-items-center justify-content-center soy-picker-modal"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="sale-modal modal-60h modal-90w"
      centered
    >
      <Modal.Header>
        <div className="float-right">
          <img
            alt=""
            style={{ transition: "none" }}
            src={crossBig}
            className="Cross-big float-right"
            onClick={() => {
              props.setShow(false);
            }}
          />
        </div>
        <Row className="align-items-center justify-content-center w-100">
          <Col md="auto" xs="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={info}
              className="info-img"
            />
          </Col>
          <Col md="auto" xs="auto">
            <div className="pfp-modal-title"> Pick the SOY to edit story</div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-5 justify-content-center">
          <ItemsList
            limit={9}
            items={props.items}
            didSelect={(selected) => props.didSelect(selected[0])}
          />
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default PickerModal;
