import React from "react";
import crossBig from "../assets/cross-big.svg";
import { useRef, useState } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import { saveAs } from "file-saver";
import URLImage from "./URLImage";
import { Stage, Layer } from "react-konva";
import twtPfp from "../assets/twitter-pfp-modified.png";
import SimpleCarousel from './SimpleCarousel';
// import bg1 from "/editor/darkbanner.png";
import info from "../assets/info.svg";

const images = [
  "/editor/darkstarsbanner.png",
  "/editor/dust_banner.png",
  "/editor/cloudsbanner.png"
];

function BannerModal(props) {
  const stageRef = useRef(null);
  const [currentBg, setBg] = useState("/editor/darkstarsbanner.png");

  const downloadBanner = () => {
    const uri = stageRef.current.toDataURL({ pixelRatio: 2.5 });
    saveAs(uri, "soy_banner.jpg");
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="special_modal align-items-center justify-content-center"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="sale-modal modal-60h modal-90w"
      centered
    >
      <Modal.Header>
        <div className="float-right">
          <img
            alt=""
            style={{ transition: "none" }}
            src={crossBig}
            className="Cross-big float-right"
            onClick={() => {
              props.setShow(false);
            }}
          />
        </div>
        <Row className="align-items-center justify-content-center w-100">
          <Col md="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={info}
              className="info-img"
            />
          </Col>
          <Col md="auto">
            <div className="pfp-modal-title"> Update your Twitter banner</div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center align-items-center">
          <div className="soy-banner-box">
            <Row>
              <Stage
                width={600}
                height={200}
                ref={stageRef}
                className="pfp-banner-canvas"
              >
                <Layer width={1500} height={500}>
                  <URLImage src={currentBg} x={0} y={0} width={600} height={200}/>
                  {props.bannerArray.map((el, index) => {
                    if (props.bannerArray.length === 4) {
                      return (
                        <URLImage
                          src={el}
                          x={-70 + 140 * index}
                          y={-40}
                          width={320}
                          height={320}
                        />
                      );
                    }
                    if (props.bannerArray.length === 3) {
                      return (
                        <URLImage
                          src={el}
                          x={-80 + 190 * index}
                          y={-70}
                          width={400}
                          height={400}
                        />
                      );
                    }
                    if (props.bannerArray.length === 2) {
                      return (
                        <URLImage
                          src={el}
                          x={-70 + 290 * index}
                          y={-100}
                          width={450}
                          height={450}
                        />
                      );
                    }
                    return (
                      <URLImage
                        src={el}
                        x={-10}
                        y={-210}
                        width={720}
                        height={720}
                      />
                    );
                  })}
                  <URLImage
                    src="/shadow.svg"
                    x={0}
                    y={0}
                    width={200}
                    height={200}
                  />
                  <URLImage
                    src="/soy_logo_w.png"
                    x={20}
                    y={20}
                    width={120}
                    height={40}
                  />
                </Layer>
              </Stage>
            </Row>
            <Row>
              <img alt="" src={twtPfp} className="twt-pfp" />
            </Row>
            <Row>
              <div className="twt-title">Your Twitter Name 👻</div>
            </Row>
            <Row>
              <div className="twt-sub">@TwitterHandle</div>
            </Row>
          </div>
        </Row>
        <Row className="align-items-center justify-content-center mb-1">
            {/* <Col md="auto">
              <img
                alt=""
                style={{ transition: "none" }}
                src={info}
                className="info-img"
              />
            </Col> */}
            <Col md="auto">
              <div className="pfp-modal-title">
                {" "}
                Pick the background you like
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center mb-3">
            <SimpleCarousel
              images={images}
              didSelect={(selected) => {
                setBg(selected);
            }}
          />
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md="auto">
            <Button
                className="main-action-button download-button modal-title-button"
                onClick={downloadBanner}
              >
                {"Download"}
              </Button>{" "}
            </Col>
          </Row>
      </Modal.Body>
    </Modal>
  );
}

export default BannerModal;
