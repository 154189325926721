import Carousel from "react-multi-carousel";
import 'react-multi-carousel/lib/styles.css';
import { Row, Col, Image } from "react-bootstrap";
import { useCallback, useState } from "react";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 50
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 30
  }
};

// Because this is an inframe, so the SSR mode doesn't not do well here.
// It will work on real devices.
const SimpleCarousel = ({didSelect, images }) => {
  const callbackFunction = useCallback(
    (selected) => {
      if (didSelect) didSelect(selected);
    },
    [didSelect]
  );

  const itemSelected = (e, index) => {
    callbackFunction(
      images[index]
    );
  };

  return (
    <Carousel
      // partialVisbile
      centerMode={true}
      deviceType="desktop"
      itemClass="image-item"
      responsive={responsive}
    >
      {images.map((image, index) => {
        return (
          <Image
            draggable={false}
            key={index}
            style={{ width: "100%", height: "100%", cursor: 'pointer' }}
            src={image}
            onClick={(e) => {
              itemSelected(e, index);
            }}
          />
        );
      })}
    </Carousel>
  );
};

export default SimpleCarousel;
